import Chip from "@mui/material/Chip";
import Pagination from "@mui/material/Pagination";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import {
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import React, { useContext } from "react";
import "../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../DashboardContext/DashboardContextProvider";
import * as defaultStyles from "../styles/main-datagrid.module.css";

// Components shared by both admin and client data grids
// Footer pagination component for the data grid
export function PaginationActions({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      className={`${className} ${defaultStyles["grid-pagination"]}`}
      variant="text"
      classes={{
        root: defaultStyles["grid-pagination-root"],
        text: defaultStyles["pagination-item-text"],
        ul: defaultStyles["pagination-ul"],
      }}
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => onPageChange(event, value - 1)}
      siblingCount={0}
    />
  );
}

// Custom pagination component for the data grid
export function CustomGridPagination(props) {
  return <GridPagination ActionsComponent={PaginationActions} {...props} />;
}

// Component for the project status column in the data grid
export function RequestStatusCell({ params, isAdmin }) {
  let { id, value } = params;

  // If the status is "I" (Incomplete)
  if (value === "I") {
    return (
      <Tooltip
        title={`The questionnaire has not been submitted and currently is available to edit. Click the 'EDIT' button to begin editing this projects questionnaire.`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`incomplete-${id}`}
          label={"Editable"}
          id={defaultStyles["incompleteChip"]}
          classes={{
            label: defaultStyles["chipStatusLabel"],
            root: defaultStyles["incompleteChipRoot"],
          }}
        />
      </Tooltip>
    );
    // If the status is "P" (Pending)
  } else if (value === "P") {
    return (
      <Tooltip
        title={
          isAdmin
            ? `The questionnaire has been submitted and is pending verification/approval. Click the "REVIEW" button to suggest revisions or validate this request`
            : `The questionnaire has been submitted and is pending verification/approval from an Argus team member. A read-only version of the questionnaire form is available, and once approved, a downloadable PDF copy of the questionnaire will be available`
        }
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`pending-${id}`}
          label={"Pending"}
          id={defaultStyles["pendingChip"]}
          classes={{
            label: defaultStyles["chipStatusLabel"],
            root: defaultStyles["pendingChipRoot"],
          }}
        />
      </Tooltip>
    );
    // If the status is "IP" (In Progress)
  } else if (value === "IP") {
    return (
      <Tooltip
        title={`Another user is currently editing this form. A read-only version of the questionnaire is available, and will be come editable once the user has finished editing`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`inProgress-${id}`}
          label={"In Progress"}
          id={defaultStyles["inProgressChip"]}
          classes={{
            label: defaultStyles["chipStatusLabel"],
            root: defaultStyles["inProgressChipRoot"],
          }}
        />
      </Tooltip>
    );
    // If the status is "RV" (Revise)
  } else if (value === "RV") {
    return (
      <Tooltip
        title="An Argus team member has marked information on the questionnaire as invalid. You can update (if applicable) & resubmit this form by clicking the 'Revise' button to access the questionnaire form"
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`revision-${id}`}
          label={"Revisions"}
          id={defaultStyles["reviseChip"]}
          classes={{
            root: defaultStyles["reviseChipRoot"],
            label: defaultStyles["chipStatusLabel"],
          }}
        />
      </Tooltip>
    );
  } else if (value === "C") {
    return (
      <Tooltip
        title={
          isAdmin
            ? `The questionnaire is validated & complete. You can view/download a PDF copy of the questionniare by clicking the 'DOWNLOAD' button. This project may now be archived`
            : `The questionnaire is validated & complete. You can view/download a PDF copy of the questionniare by clicking the 'DOWNLOAD' button`
        }
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          label={params.row.archived ? "Archived" : "Complete"}
          className={params.row.archived ? defaultStyles["archived-chip"] : ""}
          id={defaultStyles["completedChip"]}
          classes={{
            root: defaultStyles["completedChipRoot"],
            label: defaultStyles["chipStatusLabel"],
          }}
        />
      </Tooltip>
    );
  }
}

// | SERVICES POPOVER |
// Custom popover component that displays a list of services requested for a given project
export function ServicesPopover({ params }) {
  const {
    servicesAnchor,
    setServicesAnchor,
    openServices,
    setOpenServices,
    setCurrServicesRow,
  } = useContext(DashboardContext);
  return (
    <Popover
      id={defaultStyles["servicesPopover"]}
      key={params.id}
      open={openServices}
      anchorEl={servicesAnchor}
      onClose={() => {
        setServicesAnchor(null);
        setCurrServicesRow(null);
        setOpenServices(false);
      }}
      slotProps={{
        paper: {
          className: defaultStyles["popoverPaper"],
          elevation: 0,
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: -55 }}
    >
      <div className={defaultStyles["servicesContainer"]}>
        <>
          <h4 className={defaultStyles["servicesPopoverHeading"]}>
            Requested SWPPP Services
          </h4>
          {params.row.services.swppp.length >= 1 ? (
            <div className={defaultStyles["servicesList"]}>
              {params.row.services.swppp.map((service, index) => {
                return (
                  <p key={index} className={defaultStyles["servicesListItem"]}>
                    {`- ${service}`}
                  </p>
                );
              })}
            </div>
          ) : (
            <p className={defaultStyles["soloListItem"]}>
              {"- No Services Selected"}
            </p>
          )}
        </>
        <>
          <h4 className={defaultStyles["servicesPopoverHeading"]}>
            Requested Dust Control Services
          </h4>
          {params.row.services.dust.length > 1 ? (
            <div className={defaultStyles["servicesList"]}>
              {params.row.services.dust.map((service, index) => {
                return (
                  <p key={index} className={defaultStyles["servicesListItem"]}>
                    {`- ${service}`}
                  </p>
                );
              })}
            </div>
          ) : (
            <p
              className={defaultStyles["soloListItem"]}
            >{`- No Services Selected`}</p>
          )}
        </>
        {params.row.services.safety && (
          <>
            <h4 className={defaultStyles["servicesPopoverHeading"]}>
              Requested Safety Services
            </h4>
            <p className={defaultStyles["servicesListItem"]}>
              {`- ${params.row.services.safety}`}
            </p>
          </>
        )}
      </div>
    </Popover>
  );
}
