import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useManualLogin from "./LoginQueries/manualLogin";
import { decodeCompanyCode } from "./companyCodeParser.js";
import "./styles/login.css";

/**
 * Login Component
 *
 * @description
 * The login component renders a form for users to enter their company code
 * and sign in to the application. The component uses a custom mutation hook
 * to handle the sign-in logic and update the user state in Redux.
 *
 * @returns {JSX.Element} The rendered login component.
 */
const Login = () => {
  // state variables
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const enteredPassword = useRef(null);
  const user = useSelector((state) => state.user);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // Gain access to the global query cache/instance
  const queryClient = useQueryClient();
  // Custom mutation hook for logging user in with the company code
  const { enqueueSnackbar } = useSnackbar();
  const manualLogin = useManualLogin(
    dispatch,
    user,
    enqueueSnackbar,
    navigate,
    queryClient,
  );

  /**
   * On component mount, check if the user has a company code saved in the Redux
   * state. If so, decode the company code and set it as the password in the
   * component state.
   *
   * @see {@linkcode decodeCompanyCode} for the company code decoding function.
   *
   */
  useEffect(() => {
    if (password === "" && user.companyCode && user.companyCode !== "") {
      setPassword(() => decodeCompanyCode(user.companyCode));
    }
  }, []);

  /**
   * Handle sign-in form submission. If the password is at least 5 characters
   * long and the manualLogin mutation is not pending, call the mutation function
   * with the password as the argument.
   *
   * @see {@linkcode useManualLogin} for the manualLogin mutation definition.
   * @param {Event} e - The form submission event.
   * @returns {void}
   */
  const handleSignIn = (e) => {
    e.preventDefault();
    if (password.length < 5 || manualLogin.isPending) return;
    const code = password;

    // call the manualLogin mutation function
    manualLogin.mutate(code);
  };

  return (
    <>
      <div id="sign-in-container">
        <header id="sign-in-header">
          <h1 id="sign-in-heading">Company Portal Login</h1>
        </header>
        <div id="sign-in-content-container">
          <div id="sign-in-p-container">
            <p id="sign-in-p">
              {
                "Company codes are provided in email invitations. Enter your company code, or click the 'Access' button in the email to bypass signing into your company & user account. Your company code will be saved on this device for future visits."
              }
            </p>
          </div>
          <div className="input-password-container">
            <label id="company-code-label">Company Code:</label>
            <FormControl
              variant="outlined"
              id="sign-in-form"
              classes={{ root: "form-sign-in-root" }}
            >
              <InputLabel
                id="password-label"
                htmlFor="password-input"
                classes={{ shrink: "password-label-shrink" }}
              >
                Company Code (case-sensitive)
              </InputLabel>
              <OutlinedInput
                id="password-outlined-input"
                type={showPassword ? "text" : "password"}
                inputRef={enteredPassword}
                value={password}
                onChange={(e) => {
                  setPassword(() => e.target.value);
                  enteredPassword.current.value = e.target.value;
                }}
                disabled={manualLogin.isPending}
                classes={{
                  root: "password-root",
                  notchedOutline: "password-notchedOutline",
                  input: "password-root-input",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                      id="password-icon-button"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon
                          className="password-visibility"
                          id="hide-password"
                        />
                      ) : (
                        <VisibilityIcon
                          className="password-visibility"
                          id="show-password"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <Button
            id="sign-in-button"
            type="submit"
            variant="contained"
            onClick={handleSignIn}
            disabled={password.length < 5 || manualLogin.isPending}
          >
            Sign In
          </Button>
        </div>
      </div>
    </>
  );
};

export default Login;
